.remoteOverviewWidget {
  max-width: 45rem;
  display: grid;
  grid-template-columns: 1fr 1fr 10em;
  font-size: 1rem;
}

.remoteOverviewWidget span {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 0.5em;
  align-items: center;
}

.remoteOverviewWidget span * {
  margin: 0.1em;
}

.remoteOverviewWidget span p {
  float: left;
}

@media only screen and (max-width: 900px) {
  .remoteOverviewWidget {
    grid-template-columns: 1fr 1fr;
    padding: 0.5em;
  }
}
@media only screen and (max-width: 550px) {
  .remoteOverviewWidget {
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0.3em;
  }
  .remoteOverviewWidget span {
    gap: 0.1em;
  }
}
